import React, { useState, useEffect } from "react";
import { SubHeading, MenuItem } from "../../components";
import "./SpecialMenu.css";
import GetrankeItem from "../../components/GetrankeItem/GetrankeItem";
import { BASE_URL } from "../../config";

const SpecialMenu = () => {
  const [categories, setCategories] = useState([]);
  const [items, setItems] = useState({});
  const [showAllCategories, setShowAllCategories] = useState(false);
  const [showAllCategoriesDrinks, setShowAllCategoriesDrinks] = useState(false);

  useEffect(() => {
    fetch(`${BASE_URL}api/v1/Bff/Categories`, { method: "GET" })
      .then((response) => response.json())
      .then((data) => {
        setCategories(data);
        data.forEach((category) => {
          fetch(`${BASE_URL}api/v1/Bff/ItemsByCategory/${category.id}`)
            .then((response) => response.json())
            .then((itemsData) => {
              setItems((prevItems) => ({
                ...prevItems,
                [category.id]: itemsData,
              }));
            });
        });
      })
      .catch((error) => console.error("Error fetching categories:", error));
  }, []);

  const handleToggleCategories = () => {
    setShowAllCategories((prev) => {
      const newShowAll = !prev;
  
      // Если скрываем категории, скроллим к секции меню
      if (!newShowAll) {
        setTimeout(() => {
          document.getElementById('menu').scrollIntoView({ behavior: 'smooth' });
        }, 100); // Небольшая задержка для плавного скролла
      }
  
      return newShowAll;
    });
  };
  
  const handleToggleCategoriesDrinks = () => {
    setShowAllCategoriesDrinks((prev) => {
      const newShowAll = !prev;
  
      // Если скрываем категории напитков, скроллим к секции меню
      if (!newShowAll) {
        setTimeout(() => {
          document.getElementById('menu').scrollIntoView({ behavior: 'smooth' });
        }, 100); // Небольшая задержка для плавного скролла
      }
  
      return newShowAll;
    });
  };

  // Категории для еды
  const column1CategoryIds = [3, 4, 5, 8, 9];
  const column2CategoryIds = [6, 7];
  const column1Categories = categories.filter(category => column1CategoryIds.includes(category.id));
  const column2Categories = categories.filter(category => column2CategoryIds.includes(category.id));

  // Категории для напитков
  const drinkColumn1CategoryIds = [10, 14, 17, 19];
  const drinkColumn2CategoryIds = [12, 13, 11, 15, 16, 18];
  const drinkColumn1Categories = categories.filter(category => drinkColumn1CategoryIds.includes(category.id));
  const drinkColumn2Categories = categories.filter(category => drinkColumn2CategoryIds.includes(category.id));

  return (
    <div className="app__specialMenu flex__center section__padding" id="menu">
      <div className="app__specialMenu-title">
        <SubHeading title="Menü für jeden Geschmack" />
        <h1 className="headtext__cormorant">unsere Speisekarte</h1>
      </div>

      <div className="app__specialMenu-menu">
        <div className="app__specialMenu-menu_column">
          {column1Categories
           .slice(0, showAllCategories ? categories.length : 1)
          .map((category) => (
            <div key={category.id} className="app__specialMenu-menu_category">
              <p className="app__specialMenu-menu_heading">{category.title}</p>
              <div className="app__specialMenu_menu_items">
                {(items[category.id] || [])
                 .slice(0, showAllCategories ? undefined : 3)
                .map((item, index) => (
                  <MenuItem
                    key={item.title + index}
                    title={item.title}
                    price={item.price}
                    tags={item.description}
                  />
                ))}
              </div>
            </div>
          ))}
        </div>

        <div className="app__specialMenu-menu_column">
          {column2Categories
          .slice(0, showAllCategories ? categories.length : 1)
          .map((category) => (
            <div key={category.id} className="app__specialMenu-menu_category">
              <p className="app__specialMenu-menu_heading">{category.title}</p>
              <div className="app__specialMenu_menu_items">
                {(items[category.id] || [])
                .slice(0, showAllCategories ? undefined : 3)
                .map((item, index) => (
                  <MenuItem
                    key={item.title + index}
                    title={item.title}
                    price={item.price}
                    tags={item.description}
                  />
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>

      <div style={{ marginTop: "15px" }}>
        <button type="button" className="custom__button" onClick={handleToggleCategories}>
          {showAllCategories ? 'Weniger anzeigen' : 'Mehr anzeigen'}
        </button>
      </div>

      <div className="app__specialMenu-separator" />

      <div className="app__specialMenu-title">
        <SubHeading title="Trinken ist für alle wichtig!" />
        <h1 className="headtext__cormorant">unsere Getränkekarte</h1>
      </div>
      <div className="app__specialMenu-menu">
        <div className="app__specialMenu-menu_column">
          {drinkColumn1Categories
          .slice(0, showAllCategoriesDrinks ? categories.length : 1)
          .map((category) => (
            <div key={category.id} className="app__specialMenu-menu_category">
              <p className="app__specialMenu-menu_heading">{category.title}</p>
              <div className="app__specialMenu_menu_items">
                {(items[category.id] || [])
                .slice(0, showAllCategoriesDrinks ? undefined : 3)
                .map((item, index) => (
                  <GetrankeItem
                    key={item.title + index}
                    title={item.title}
                    subtitle={item.subtitle}
                    tags={item.description}
                  />
                ))}
              </div>
            </div>
          ))}
        </div>
        <div className="app__specialMenu-menu_column">
          {drinkColumn2Categories
          .slice(0, showAllCategoriesDrinks ? categories.length : 1)
          .map((category) => (
            <div key={category.id} className="app__specialMenu-menu_category">
              <p className="app__specialMenu-menu_heading">{category.title}</p>
              <div className="app__specialMenu_menu_items">
                {(items[category.id] || [])
                .slice(0, showAllCategoriesDrinks ? undefined : 3)
                .map((item, index) => (
                  <GetrankeItem
                    key={item.title + index}
                    title={item.title}
                    subtitle={item.subtitle}
                    tags={item.description}
                  />
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>

      <div style={{ marginTop: "15px" }}>
        <button type="button" className="custom__button" onClick={handleToggleCategoriesDrinks}>
          {showAllCategoriesDrinks ? 'Weniger anzeigen' : 'Mehr anzeigen'}
        </button>
      </div>

    </div>
  );
};

export default SpecialMenu;
