import chef from '../assets/chef2.jpg';
import gallery01 from '../assets/1.jpg';
import gallery02 from '../assets/2.jpg';
import gallery03 from '../assets/3.jpg';
import gallery04 from '../assets/4.jpg';
import knife from '../assets/knife4.png';
import overlaybg from '../assets/overlaybg.png';
import spoon from '../assets/spoon.svg';
import welcome from '../assets/welcome2.jpg';
import findus from '../assets/findus.jpg';
import quote from '../assets/quote.png';
import gericht from '../assets/logo6.png';

const images = {
  chef,
  gallery01,
  gallery02,
  gallery03,
  gallery04,
  knife,
  overlaybg,
  spoon,
  welcome,
  findus,
  quote,
  gericht,
};

export default images;
