import React from 'react';
import { SubHeading } from "../../components";

import { images } from "../../constants";
import "./Header.css";

const Header = () => {

  return (
  <div className="app__header app__wrapper section__padding" id="home">
    <div className="app__wrapper_info">
      <SubHeading title="Genießen Sie in einer gemütlichen und entspannten Atmosphäre unsere Speisen aus der deutschen und russischen Küche!" />
      <h1 className="app__header-h1">Kegelgaststätte</h1>
      <h1 className="app__header-h1">Alle Neune</h1>
      <p className="p__opensans" style={{ margin: "2rem 0" }}>
          Liebe Gäste!<br />
          Wir haben für Sie täglich ab 11:30 Uhr geöffnet.<br />
          In der Woche bis 21:00 Uhr.<br />
          Am Freitag und Samstag bis 22:00 Uhr.<br />
          (Küchenschluss - 20:00 Uhr)<br />
          Unsere Gaststätte bietet Ihnen gutbürgerliche deutsche Küche und einige russische Spezialitäten.<br />
      </p>
      
      <button type="button" className="custom__button">
        <a href="#menu">
          Unsere Speisekarte
        </a>
      </button>
    </div>

    <div className="app__wrapper_img">
      <img src={images.welcome} alt="header img" />
    </div>
  </div>
  )
};

export default Header;
