import React from 'react';
import { Navbar } from '../';
import { Footer } from '../../container';

const PageLayout = ({ children }) => {
    return (
        <>
            <Navbar />
            <div className="content">
                {children}
            </div>
            <Footer />
        </>
    );
};

export default PageLayout;
