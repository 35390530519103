import React from "react";

import "./MenuItem.css";

const MenuItem = ({ title, price, tags }) => {
  const formattedPrice = parseFloat(price).toFixed(2);

  return (
    <div className="app__menuitem">
      <div className="app__menuitem-head">
        <div className="app__menuitem-name">
        <p className="p__cormorant app__menuitem-smallText" style={{ color: "#DCCA87" }}>
            {title}
          </p>
        </div>

        <div className="app__menuitem-dash" />

        {formattedPrice !== '0.00' && ( 
          <div className="app__menuitem-price">
            <p className="p__cormorant">€{formattedPrice}</p> {}
          </div>
        )}
      </div>
      <div className="app__menuitem-sub">
      <p className="p__opensans app__menuitem-smallSubText" style={{ color: "#AAA" }}>
          {tags}
        </p>
      </div>
    </div>
  );
};

export default MenuItem;
