import React from "react";

import { SubHeading } from "../../components";
import { images } from "../../constants";
import "./Chef.css";

const Chef = () => (
  <div className="app__bg app__wrapper section__padding">
    <div className="app__wrapper_img app__wrapper_img-reverse">
      <img src={images.chef} alt="chef" />
    </div>

    <div className="app__wrapper_info">
      <h1 className="headtext__cormorant">Woran wir glauben</h1>

      <div className="app__chef-content">
        <div className="app__chef-content_quote">
          <img src={images.quote} alt="quote" />
          <p className="p__opensans">
            Lieber Leser,
          </p>
        </div>
        <p className="p__opensans"style={{ fontSize: "26px" }}>
        <br />&nbsp;&nbsp;&nbsp;&nbsp;eine kurze Geschichte über einen schweren und trotzdem spannenden und erlebnisreichen Weg zu einer guten Gaststätte für Familien und Freunde mitten in Freital am "Stadion des Friedens!"<br />
        &nbsp;&nbsp;&nbsp;&nbsp;Seit dem 06.12.2015 bin ich, Natalja, Betreiberin der Kegelgaststätte "Alle Neune". Gebürtig aus Kasachstan, kam ich im Jahr 2000 nach Deutschland. Im Jahr 2011 hat mich mein Weg in die "Alle Neune" gebracht, zuerst als Aushilfe. Ich habe mich gleich in alles hier verliebt. Und als meine liebe Chefin in den Ruhestand ging, habe ich die Gaststätte übernommen. Es war für mich, als wäre mein Traum in Erfüllung gegangen!<br />
        &nbsp;&nbsp;&nbsp;&nbsp;Es war sehr spannend, herausfordernd und hat Spaß gemacht. Wir haben einiges verändert. Aus einer Gaststätte für Sportler sind wir zu einer Gaststätte geworden, die unter anderem Familien-, Firmen-, Freunde- und Kollegenfeiern ausrichtet. Unsere breite Speisekarte wurde sehr gut angenommen, genauso wie das Buffet- und Catering-Angebot. Mit der Zeit haben wir auch einige russische Spezialitäten eingeführt. Trotz all der Schwierigkeiten arbeiten wir weiterhin leidenschaftlich gern und würden uns freuen, Sie als Gast bei uns begrüßen zu dürfen. Der Weg zu uns lohnt sich!<br /><br />

        Herzliche Grüße <br />
        Natalja und Team
        </p>
      </div>

      <div className="app__chef-sign">
        <p>Natalja Potschujew</p>
        <p className="p__opensans">Inhaberin</p>
      </div>
    </div>
  </div>
);

export default Chef;
