import React from "react";

import { images } from "../../constants";
import "./AboutUs.css";

const AboutUs = () => (
  <div
    className="app__aboutus app__bg flex__center section__padding"
    id="about"
  >
    <div className="app__aboutus-content flex__center">
      <div className="app__aboutus-content_about">
        <h1 className="headtext__cormorant">Über uns</h1>
        <img src={images.spoon} alt="about_spoon" className="spoon__img" />
        <p className="p__opensans">
        Am Wochenende gestalten wir gerne Ihre Feier, sei es Geburtstag, Jubiläum, Jugendweihe oder Konfirmation. Auch Schuleinführungen und betriebliche Feiern gehören dazu. Bis zu 50 Personen können dabei unser Angebot nutzen. Genießen Sie den Abend oder Tag sorgenlos mit Ihren Gästen in einer lockeren Atmosphäre. Leckeres Essen und Trinken sind selbstverständlich dabei.
        </p>
      </div>

      <div className="app__aboutus-content_knife flex__center">
        <img src={images.knife} alt="about_knife" />
      </div>

      <div className="app__aboutus-content_history">
        <h1 className="headtext__cormorant">Über Kegelbahnen</h1>
        <img src={images.spoon} alt="about_spoon" className="spoon__img" />
        <p className="p__opensans">
        Zusätzlich können Sie unsere Kegelbahnen buchen, die für Ihre Gäste ein echtes Highlight darstellen. Die Gebühr beträgt 10,00€/Std./Bahn. Bitte reservieren Sie im Voraus und bringen Sie Sportschuhe sowie gute Laune mit. Unsere Kegelbahnen bieten jede Menge Spaß und Unterhaltung für alle. Damit wird Ihre Feier noch abwechslungsreicher!<br /> <br />
        </p>
      </div>
    </div>
  </div>
);

export default AboutUs;
