import React, { useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { MdOutlineRestaurantMenu } from "react-icons/md";
import { BASE_URL } from "../../config";
import images from "../../constants/images";
import "./Navbar.css";

const Navbar = ({ isAuthenticated }) => {
  const [toggleMenu, setToggleMenu] = useState(false);

  return (
    <nav className="app__navbar">
      <div className="app__navbar-logo">
      <a href={`${BASE_URL}#home`}>
        <img src={images.gericht} alt="app logo" />
        </a>
      </div>
      <ul className="app__navbar-links">
        <li className="p__opensans">
          <a href={`${BASE_URL}#home`}>Startseite</a>
        </li>
        <li className="p__opensans">
          <a href={`${BASE_URL}#about`}>Über uns</a>
        </li>
        <li className="p__opensans">
          <a href={`${BASE_URL}#menu`}>Menü</a>
        </li>
        <li className="p__opensans">
          <a href={`${BASE_URL}#contact`}>Kontakt</a>
        </li>
        <li className="p__opensans">
          <a href={`${BASE_URL}#reserve`}>Reservierung</a>
        </li>
      </ul>
      <div className="app__navbar-smallscreen">
        <GiHamburgerMenu
          color="#fff"
          fontSize={27}
          onClick={() => setToggleMenu(true)}
        />

        {toggleMenu && (
          <div className="app__navbar-smallscreen_overlay flex__center slide-bottom">
            <MdOutlineRestaurantMenu
              fontSize={27}
              className="overlay__close"
              onClick={() => setToggleMenu(false)}
            />
            <ul className="app__navbar-smallscreen_links">
              <li className="p__opensans">
                <a href={`${BASE_URL}#home`} onClick={() => setToggleMenu(false)}>
                Startseite
                </a>
              </li>
              <li className="p__opensans">
                <a href={`${BASE_URL}#about`} onClick={() => setToggleMenu(false)}>
                Über uns
                </a>
              </li>
              <li className="p__opensans">
                <a href={`${BASE_URL}#menu`} onClick={() => setToggleMenu(false)}>
                  Menü
                </a>
              </li>
              <li className="p__opensans">
                <a href={`${BASE_URL}#contact`} onClick={() => setToggleMenu(false)}>
                Kontakt
                </a>
              </li>
              <li className="p__opensans">
                <a href={`${BASE_URL}#reserve`} onClick={() => setToggleMenu(false)}>
                Reservierung
                </a>
              </li>
            </ul>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
