import React, { useEffect, useState } from 'react';
import PageLayout from '../PageLayout/PageLayout';
import './Impressum.css';

const Impressum = () => {
  const [navbarHeight, setNavbarHeight] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);
    const navbar = document.getElementById('app__navbar');
    if (navbar) {
      setNavbarHeight(navbar.offsetHeight);
    }
  }, []);
  return (
    <PageLayout>
    <div className="impressum">
    <h1>Impressum</h1>

    <p>Natalja Potschujew<br />
    Kegelgastst&auml;tte &quot;Alle Neune&quot;<br />
    Richard-Hofmann-Weg 5<br />
    01705 Freital</p>

    <h2>Kontakt</h2>
    <p>Telefon: 03516492118<br />
    E-Mail: kegelgaststaette.alle-neune@gmx.de</p>

    <h2>Umsatzsteuer-ID</h2>
    <p>Umsatzsteuer-Identifikationsnummer gem&auml;&szlig; &sect; 27 a Umsatzsteuergesetz:<br />
    DE331763799</p>

    <h2>Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle</h2>
    <p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>

    </div>
    </PageLayout>
  );
};

export default Impressum;
