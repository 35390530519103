import React from "react";

import { SubHeading } from "../../components";
import { images } from "../../constants";

const FindUs = () => (
  <div className="app__bg app__wrapper section__padding" id="contact">
    <div className="app__wrapper_info">
      <SubHeading title="Kontakt" />
      <h1 className="headtext__cormorant" style={{ marginBottom: "3rem" }}>
        Finde uns
      </h1>
      <a
        href="https://maps.app.goo.gl/h6i9qGp28ZqutCLc9">
        <div className="app__wrapper-content">
          <p className="p__opensans">
            Richard-Hofmann-Weg 5, Freital, Germany
          </p>
        <p
          className="p__cormorant"
          style={{ color: "#DCCA87", margin: "2rem 0" }}
        >
          Öffnungszeiten
        </p>
        <p className="p__opensans">So. - Do.: 11:30 - 21:00</p>
        <p className="p__opensans">Fr. - Sa.: 11:30 - 22:00</p>
      </div>
      </a>
      <a
        href="https://maps.app.goo.gl/h6i9qGp28ZqutCLc9">
      <button
        type="button"
        className="custom__button"
        style={{ marginTop: "2rem" }}
      >
        Besuchen Sie uns
      </button>
      </a>
    </div>
    <div className="app__wrapper_img">
      <img src={images.findus} alt="findus" />
    </div>
  </div>
);

export default FindUs;
